<template>
	<div data-test="workflow-panel">
		<v-expansion-panel
			:disabled="isLoading"
			class="w-bordered-panel">
			<v-expansion-panel-content>
				<template v-slot:header>
					<v-flex
						v-t="'customers.tabs_title.workflow'"
						class="w-expansion-panel-title"
					/>
				</template>

				<div v-show="isLoadingNodeWorkflows && !selectedNode.is_folder" class="px-3 mb-4">
					<v-progress-linear color="primary" indeterminate :height="2" />
				</div>

				<template v-if="!selectedNode.is_folder">
					<v-list-tile v-for="workflow in activeWorkflows" :key="workflow.id">
						<v-list-tile-content>
							<SelectWithChips
								data-test="workflow-panel-select"
								:disabled="isGuest || isLoadingNodeWorkflows || isWorkflowLoading(workflow.id)"
								:items="workflow.statuses"
								:item-clearable="false"
								item-short-text="short_title"
								item-text="name"
								item-value="id"
								:label="workflow.name"
								list-with-chips
								:loading="isWorkflowLoading(workflow.id)"
								return-object
								style="width: 100%"
								:value="findSelectedWorkflowStatus(workflow)"
								with-short-text-tooltip
								@change="updateWorkflowStatus(workflow.id, $event, true)"
							>
								<template v-slot:item-action="{ value }">
									<v-icon :color="value ? 'primary' : 'transparent'" small class="mr-1">
										check
									</v-icon>
								</template>
							</SelectWithChips>
						</v-list-tile-content>
					</v-list-tile>
				</template>

				<v-container
					v-if="userHasWriteAccess"
					data-test="workflow-panel-switch"
					class="pt-2 pb-4"
				>
					<w-switch
						v-for="workflow in workflows"
						:key="workflow.id"
						v-model="workflow.isActive"
						:loading="isLoadingNodeWorkflows"
						:disabled="isLoadingNodeWorkflows"
						class="justify-space-between"
						@click.stop.prevent="onToggleWorkflowClick(workflow)"
					>
						<template v-slot:prepend>
							<label :class="labelClass(workflow.isActive)">{{ workflow.name }}</label>
						</template>
					</w-switch>

					<div v-if="!hasInitialWorkflows">
						<p>
							{{ $t('workflows.ecm.none') }}
						</p>
						<w-btn
							class="d-block mx-auto mt-3 mb-4"
							@click="goToWorkflowDrawer"
						>
							{{ $t('workflows.actions.create') }}
						</w-btn>
					</div>
				</v-container>
			</v-expansion-panel-content>
		</v-expansion-panel>

		<w-dialog
			v-if="displayDeleteDialog"
			v-model="displayDeleteDialog"
			max-width="500px"
			:title="$t('workflows.actions.ecm.delete_workflow.confirmation_title', { name: togglingWorkflow.name })"
		>
			<v-layout row>
				<p class="subheading my-2">{{ $t('workflows.actions.ecm.delete_folder_workflow.confirmation_text', { name: togglingWorkflow.name }) }}</p>
			</v-layout>
			<v-layout row>
				<v-flex xs12>
					<v-radio-group v-model="togglingWorkflow.cascade" row>
						<v-radio
							:label="$t('workflows.actions.delete.cascade_true')"
							:value="true"
							class="mb-2"
						/>
						<v-radio
							:label="$t('workflows.actions.delete.cascade_false')"
							:value="false"
						/>
					</v-radio-group>
				</v-flex>
			</v-layout>
			<template v-slot:actions>
				<v-layout row justify-end>
					<w-btn flat color="secondary" @click="displayDeleteDialog = false">{{ $t('actions.cancel') }}</w-btn>
					<w-btn
						:disabled="togglingWorkflow.cascade === null"
						color="error"
						@click="toggleWorkflow(togglingWorkflow, true)"
					>{{ $t('actions.deactivate') }}
					</w-btn>
				</v-layout>
			</template>
		</w-dialog>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import SelectWithChips from "@/components/Commons/SelectWithChips.vue";
import WorkflowManagerModuleGuard from "@/mixins/ModulesGuards/Workflow/WorkflowManagerModuleGuard";

export default {
	name: 'WorkflowPanel',
	components: { SelectWithChips },
	mixins: [WorkflowManagerModuleGuard],
	data() {
		return {
			displayDeleteDialog: false,
			togglingWorkflow: null,
			loadingStatuses: {},
			selectedWorkflowsStatuses: {},
			workflows: [],
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			isGuest: state => state.company.userRoles.isGuest,
			selectedNode: state => state.documents.selection.current,
			isDarkModeEnabled: state => state.user.darkMode,
			isLoading: state => state.workflows.isLoading,
			hasInitialWorkflows: state => state.workflows.hasInitialWorkflows,
			vendorWorkflows: state => state.workflows.workflows,
		}),
		...mapGetters({
			userHasWriteAccess: 'workflows/userHasWriteAccess',
			nodeIsLoadingWorkflows: 'workflows/nodeIsLoadingWorkflows',
		}),
		activeWorkflows: function() {
			return this.workflows.filter(({isActive}) => isActive)
		},
		isWorkflowLoading() {
			return workflowId => this.loading || this.loadingStatuses[workflowId];
		},
		isLoadingNodeWorkflows() {
			return this.nodeIsLoadingWorkflows(this.selectedNode.id);
		},
	},
	watch: {
		selectedNode: {
			handler(value, oldValue) {
				if (value.id === oldValue?.id) {
					return;
				}
				this.loadingStatuses = {};
				this.selectedWorkflowsStatuses = {};
				this.workflows.forEach(workflow => {
					workflow.isActive = false;
				});
				this.loadNodeWorkflows()
			},
			immediate: true
		}
	},
	async created() {
		if (this.hasInitialWorkflows) {
			this.workflows = this.vendorWorkflows
			return;
		}

		this.workflows = await this.loadVendorWorkflows(this.vendorId)
	},
	methods: {
		...mapMutations({
			setWorkflows: 'workflows/setWorkflows',
			replaceWorkflow: 'workflows/replaceWorkflow',
			removeWorkflow: 'workflows/removeWorkflow',
			addVersionWorkflowStatus: 'workflows/addVersionWorkflowStatus',
			setNodesWorkflows: 'workflows/setNodesWorkflows',
		}),
		...mapActions({
			loadVendorWorkflows: 'workflows/loadVendorWorkflows',
		}),
		goToWorkflowDrawer() {
			this.appService.goTo(
				{
					name: this.isAccountant ? 'customer-workflows-drawer' : 'company-settings-workflows-drawer',
					params: {
						workflowId: 'create',
					},
					query: {
						backLink: this.$route.path
					}
				},
				true
			)
		},
		async onToggleWorkflowClick(workflow) {
			if (workflow.isActive) {
				await this.promptDeleteConfirmation(workflow);
				return;
			}

			const toggleValue = workflow.isActive;
			await this.toggleWorkflow(workflow, toggleValue);
		},
		async toggleWorkflow(workflow, toggleValue) {
			this.workflows.find(wf => wf.id === workflow.id).isActive = !toggleValue;
			this.displayDeleteDialog = false;
			(await (toggleValue ? this.deactivateWorkflow(workflow) : this.activateWorkflow(workflow)));
		},
		async activateWorkflow(workflow) {
			const data = await this.service.createNodeWorkflow(this.vendorId, this.selectedNode.id, workflow.id);
			this.handleWorkflowActivation(workflow, data);
		},
		async deactivateWorkflow(workflow) {
			const params = this.selectedNode.is_folder ? { cascade: workflow.cascade } : {};
			await this.service.deleteNodeWorkflow(this.vendorId, this.selectedNode.id, workflow.id, params);
			this.handleWorkflowDeactivation(workflow);
		},
		async promptDeleteConfirmation(workflow) {
			if (this.selectedNode.is_folder) {
				this.togglingWorkflow = { ...workflow, cascade: null };
				this.displayDeleteDialog = true;
				return;
			}
			const confirmed = await this.$dialog.confirm({
				text: this.$t('workflows.actions.ecm.delete_workflow.confirmation_text', { name: workflow.name }),
				title: this.$t('workflows.actions.ecm.delete_workflow.confirmation_title', { name: workflow.name }),
				actions: {
					false: this.$t('actions.cancel'),
					true: {
						text: this.$t('actions.delete'),
						color: 'error',
						flat: false,
					}
				}
			});

			if (!confirmed) {
				return;
			}
			await this.toggleWorkflow(workflow, true);
		},
		handleWorkflowActivation(workflow, data) {
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workflows.activated'));
			this.replaceWorkflow(workflow);
			if(this.selectedNode.is_folder) {
				return;
			}
			this.loadWorkflowStatuses([data.workflow_id]);
		},
		handleWorkflowDeactivation(workflow) {
			delete this.selectedWorkflowsStatuses[workflow.id];
			if (this.selectedNode.is_folder) {
				return;
			}
			this.selectedNode.versions.forEach(version =>
				this.removeWorkflow({ workflowId: workflow.id, versionId: version.id })
			);
		},
		async updateWorkflowStatus(workflowId, status, sync = false) {
			const statusId = sync ? status.id : status.workflow_status_id;
			if (this.selectedWorkflowsStatuses[workflowId] === statusId) {
				return;
			}

			try {
				this.selectedWorkflowsStatuses[workflowId] = statusId;

				if (sync) {
					const newStatus = await this.service.createNodeWorkflowStatus(this.vendorId, this.selectedNode.id, statusId);
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('workflows.status_updated'));
					this.addVersionWorkflowStatus({ status: newStatus, versionId: this.selectedNode.latestVersion.id });
				} else {
					this.addVersionWorkflowStatus({ status, versionId: this.selectedNode.latestVersion.id });
				}
			} catch (error) {
				delete this.selectedWorkflowsStatuses[workflowId];
				throw error;
			}
		},
		findSelectedWorkflowStatus(workflow) {
			if (this.loading || this.loadingStatuses[workflow.id]) {
				return [];
			}
			return workflow.statuses.find(status => status.id === this.selectedWorkflowsStatuses[workflow.id]) ?? workflow.statuses[0]
		},
		async loadNodeWorkflows() {
			const nodeWorkflows = await this.$store.dispatch('workflows/loadNodeWorkflows', { vendorId: this.vendorId, nodeId: this.selectedNode.id })
			const activeWorkflowIds = new Set(nodeWorkflows.map(nw => nw.workflow_id));

			this.workflows.forEach(wf => {
				wf.isActive = activeWorkflowIds.has(wf.id);
			});

			this.setNodesWorkflows({ nodeId: this.selectedNode.id, workflows: this.workflows.filter(wf => wf.isActive).map(wf => wf.id) });

			activeWorkflowIds.forEach(workflowId => {
				this.loadingStatuses = {
					...this.loadingStatuses,
					[workflowId]: true
				};
			})

			if (this.selectedNode.is_folder) {
				return;
			}

			try {
				await this.loadWorkflowStatuses(activeWorkflowIds)
			} finally {
				activeWorkflowIds.forEach(workflowId => {
					this.loadingStatuses = {
						...this.loadingStatuses,
						[workflowId]: false
					};
				})
			}
		},
		async loadWorkflowStatuses(workflowIds) {
			const promises = [...workflowIds].map(workflowId => this.service.getNodeWorkflowStatuses(this.vendorId, this.selectedNode.id, {
				node_version_id: this.selectedNode.latestVersion.id,
					workflow_id: workflowId,
					latest: true,
					show_error: false
			}));
			const statuses = (await Promise.all(promises)).flat();
			this.activeWorkflows.forEach(workflow => {
				const activeStatus = statuses.find(status => status.workflow_id === workflow.id);
				if (!activeStatus) {
					this.updateWorkflowStatus(workflow.id, statuses[0]);
					return;
				}
				if (this.selectedWorkflowsStatuses[workflow.id] !== activeStatus.workflow_status_id) {
					this.updateWorkflowStatus(workflow.id, activeStatus);
				}
			});
		},
		labelClass(isActive) {
			const colors =  {
				true: this.isDarkModeEnabled ? '' : 'black--text',
				false: 'grey--text',
			}

			return colors[isActive]
		},
	},
}
</script>

